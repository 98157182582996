<template>
  <div class="main">
    <div class="section">
      <div class="label">风险分</div>
      <div>
        <p>风险分引用美国EWG（环境工作组）对化妆品成分的安全风险评估。</p>
        <p>
          <svg class="iconfont" aria-hidden="true">
            <use xlink:href="#icon-daojiaojuxing-green"></use>
          </svg>
          表示很安全
        </p>
        <p>
          <svg class="iconfont" aria-hidden="true">
            <use xlink:href="#icon-daojiaojuxing-yellow"></use>
          </svg>
          表示中等
        </p>
        <p>
          <svg class="iconfont" aria-hidden="true">
            <use xlink:href="#icon-daojiaojuxing-red"></use>
          </svg>
          提示该成分有一定风险
        </p>
        <p>
          但是，安全风险高并不意味着成分不好，比如视黄醇等功效成分也会标注成红色。
        </p>
        <p>
          安全风险等级可为敏感肌、孕妇、儿童等关注成分安全的用户提供一定参考，而耐受性肌肤可以使用的成分很广泛，不需要对安全风险标注过度恐慌。
        </p>
      </div>
    </div>
    <div class="section">
      <div class="label">成分表顺序</div>
      <div class="sub-label">1%分界线</div>
      <p>
        根据《化妆品安全技术规范》指导要求，香精、防腐剂的含量一般不会超过1%，排在这些成分之前的成分含量大于1%，之后的成分含量小于1%。
      </p>
      <div class="sub-label">前7位成分</div>
      <p>
        一款产品起主要作用的成分是前7位成分
      </p>
      <div>
        <div class="sub-label">微量成分</div>
        <p>
          国家药监局关于发布实施《化妆品标签管理办法》的公告（2021年第77号）第十二条规定：化妆品标签应当在销售包装可视面标注化妆品全部成分的名称，并以“成分”作为引导语引出，并按照配方含量的降序列出。化妆品配方中存在含量不超过0.1%（w/w）的成分的，所有不超过0.1%（w/w）的成分应当以“其他微量成分”作为引导语引出另行标注，可以不按照成分含量的降序列出。
        </p>
        <van-collapse v-model="weiliang">
          <van-collapse-item class="info" title="如何理解微量成分？" name="1">
            其实，新规虽区别出微量添加，但是并不是完全否定微量添加的有效性，还有很大一部分成分，其含量不需要达到0.1%（w/w）就能发挥其功效，由此可见，微量成分并不等于无效。
          </van-collapse-item>
        </van-collapse>
      </div>

    </div>
    <div class="section">
      <div class="label">活性成分</div>
      <div>
        <p>
          <svg class="iconfont" aria-hidden="true">
            <use xlink:href="#icon-fenzi"></use>
          </svg>
          表示对皮肤有益的活性成分
        </p>
        <p>
          <svg class="iconfont" aria-hidden="true">
            <use xlink:href="#icon-UVA"></use>
          </svg>
          UVA紫外吸收剂，防止皮肤晒黑。
        </p>
        <p>
          <svg class="iconfont" aria-hidden="true">
            <use xlink:href="#icon-UVB"></use>
          </svg>
          UVB紫外吸收剂，防止皮肤晒红、晒伤。
        </p>
      </div>
      <div class="sub-label">如何认识UVA和UVB？</div>
      <div>
        <p>UVA紫外吸收剂：能吸收波长320～420nm的紫外线，防止皮肤晒黑。</p>
        <p>UVB紫外吸收剂：能吸收波长290～320nm的紫外线，防止皮肤晒红、晒伤。</p>
      </div>

    </div>

    <div class="section">
      <div class="label">成分目的和功效</div>
      <p>
        表明该成分在化妆品中最普遍的作用，比如用来美白、保湿、抗炎、防晒、增稠、防腐等。 信息来源主要是国家药品监督管理局备案信息以及皮肤医学界研究文献。
      </p>
    </div>

    <div class="section" id="makeup-risk">
      <div class="label">常见成分隐患说明</div>
      <van-collapse v-model="risk">
        <van-collapse-item class="info" title="香精香料" name="1">
          香精的风险点在于其真实构成的化学物质的不公开性。不同气味的香精背后的化学物质各不相同，我们无法通过成分表来知晓，大部分香精通常会产生刺激、致敏等风险，一些小厂的香精原料也可能存在不确定性的风险，因此，我们提醒您注意这一成分，您可以根据自身情况做出判断和选择。
        </van-collapse-item>
        <van-collapse-item class="info" title="致痘" name="2">
          标注有致痘性的成分，说明该成分的致痘性比较高，并不代表使用了含有成分的产品一定会致痘，和使用量、个人肤质有关系，比如痘痘肌，最好避开此成分。
        </van-collapse-item>
        <van-collapse-item class="info" title="酒精" name="3">
          学名乙醇，是一种常见的溶剂，具有促进皮肤吸收、去油、抑菌等作用，一般化妆水或香水中添加较多， 频繁长期使用酒精量高的产品， 或可破坏皮肤表面的皮脂膜，破坏皮肤屏障，造成皮肤敏感。
          不过，由于酒精易挥发性，一般使用低浓度问题不大，油性皮肤还有很好的去油作用，干性和敏感肌则慎用
        </van-collapse-item>
        <van-collapse-item class="info" title="防腐剂" name="4">
          防腐剂在化妆品不可或缺，以防止产品微生物污染和二次污染引发产品变质，大多数防腐剂对皮肤的风险主要在于其刺激、致敏性。
          0防腐剂产品大多因产品配方本身具有防腐特性，如添加了可抑菌的果酸或二元醇类等成分，使用感受相对温和，或包装上采用了特别的密封工艺。
        </van-collapse-item>
        <van-collapse-item class="info" title="尼泊金酯防腐剂" name="5">
          尼泊金酯类防腐剂即羟苯甲酯、羟苯乙酯、羟苯丙酯、羟苯丁酯等，其争议点主要在于其会产生内分泌干扰，进而可能产生引发乳腺癌的风险，但美国FDA（美国食品药品监督管理局） 在2018年针对尼泊金酯类防腐剂的致癌性做了明确解答，并未发现现有的任何科学研究证明尼泊金酯类防腐剂具有致癌性。我们会持续追踪最新的科学研究，您可根据个人偏好进行选择是否使用。
        </van-collapse-item>
      </van-collapse>
    </div>
    <div class="van-safe-area-bottom"></div>
  </div>

</template>

<script>

import {
  ref,
} from 'vue';
import {Icon, Tag, Collapse, CollapseItem} from 'vant';

export default {
  setup() {

    const weiliang = ref([])
    const risk = ref([])
    return {
      weiliang,
      risk
    };
  },

  components: {
    [Icon.name]: Icon,
    [Tag.name]: Tag,
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem
  }
};
</script>
<style scoped>

.section {
  margin-top: 8px;
  background-color: #fff;
  padding: 10px 15px;
}

.section .label {
  font-size: 20px;
  font-weight: 700;
  padding: 5px 0;
}
.section .sub-label {
  font-size: 16px;
  font-weight: 700;
  padding: 5px 0;
}


</style>

<style>
#makeup-risk .info .van-collapse-item__content,
#makeup-risk .info .van-collapse-item__title {
  padding: 5px 0;
}
</style>
